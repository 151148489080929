import Link from 'next/link';
import React, { useState } from 'react';
import FullWidthContainer from '../Layout/Containers/FullWidthContainer';
import LargeWidthContainer from '../Layout/Containers/LargeWidthContainer';
import { MediaComponent } from '../util/MediaComponent/MediaComponent';
import DesktopHeaderComponent from './DesktopHeader';
import styles from './header.module.scss';
import MobileHeaderComponent from './MobileHeader';

const HeaderComponent = () => {
    const [background, setBackground] = useState('');

    const onSetBackground = (backgroundColor: string) => {
        setBackground(backgroundColor);
    };

    return (
        <>
            <div id="header-menu">
                <FullWidthContainer extraClassNames={`${styles.headerContainer} ${background}`}>
                    <LargeWidthContainer
                        extraStyles={{ height: '100%' }}
                        extraClassNames={styles.headerPadding}
                    >
                        <nav className={styles.header}>
                            <Link href="/">
                                <a className={styles.logo}>
                                    <MediaComponent
                                        src="/images/logo.webp"
                                        alt="Go back to home page"
                                        maxHeight={27}
                                        maxWidth={114}
                                        minWidth={114}
                                    />
                                </a>
                            </Link>
                            <DesktopHeaderComponent setBackground={onSetBackground} />
                            <MobileHeaderComponent setBackground={onSetBackground} />
                        </nav>
                    </LargeWidthContainer>
                </FullWidthContainer>
            </div>
        </>
    );
};

export default HeaderComponent;
