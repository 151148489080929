import React, { useEffect } from 'react';
import activeDropdown from './ActiveLink/activeDropdown';
import ActiveLink from './ActiveLink/ActiveLink';
import styles from './header.module.scss';
import HeaderArrow from './icons/HeaderArrow';

type HeaderProps = {
    setBackground: Function;
};

const DesktopHeaderComponent = ({ setBackground }: HeaderProps) => {
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                setBackground(styles.background);
            } else {
                setBackground(styles.noBackground);
            }
        });
    }, []);

    return (
        <div className={styles.navbar}>
            <button className={styles.dropdownMenu}>
                <span
                    className={`${styles.dropdownNavElement} ${
                        activeDropdown() === 'about-us' ? 'active' : ''
                    }`}
                >
                    Company
                    <HeaderArrow />
                </span>
                <div className={`${styles.dropdownSubmenu} ${styles.companyDropdownSubmenu}`}>
                    <ActiveLink href="/about-us">
                        <a>About Noria</a>
                    </ActiveLink>
                    <ActiveLink href="/about-us/management-team">
                        <a>Management team</a>
                    </ActiveLink>
                </div>
            </button>
            <button className={styles.dropdownMenu}>
                <span
                    className={`${styles.dropdownNavElement} ${
                        activeDropdown() === 'solutions' ? 'active' : ''
                    }`}
                >
                    Solutions
                    <HeaderArrow />
                </span>
                <div className={`${styles.dropdownSubmenu} ${styles.solutionsDropdownSubmenu}`}>
                    <ActiveLink href="/solutions">
                        <a>Overview</a>
                    </ActiveLink>
                    <ActiveLink href="/solutions/insurance">
                        <a>Insurance Software</a>
                    </ActiveLink>
                    <ActiveLink href="/solutions/customer-centric-platform">
                        <a>Customer Centric Platform</a>
                    </ActiveLink>
                    <ActiveLink href="/solutions/insight">
                        <a>Insight</a>
                    </ActiveLink>
                </div>
            </button>
            <button className={`${styles.dropdownMenu}`}>
                <span
                    className={`${styles.dropdownNavElement} ${
                        activeDropdown() === 'services' ? 'active' : ''
                    }`}
                >
                    Services
                    <HeaderArrow />
                </span>
                <div className={`${styles.dropdownSubmenu} ${styles.servicesDropdownSubmenu}`}>
                    <ActiveLink href="/services">
                        <a>Overview</a>
                    </ActiveLink>
                    <ActiveLink href="/services/digital-strategy">
                        <a>Digital Strategy</a>
                    </ActiveLink>
                    <ActiveLink href="/services/transformation-delivery">
                        <a>Transformation Delivery</a>
                    </ActiveLink>
                    <ActiveLink href="/services/software-engineering-and-solution-architecture">
                        <a>Software Engineering & Solution Architecture</a>
                    </ActiveLink>
                    <ActiveLink href="/services/insights-and-analytics">
                        <a>Insights & Analytics</a>
                    </ActiveLink>
                    <ActiveLink href="/services/quality-assurance-and-test-engineering">
                        <a>Quality Assurance & Test Engineering</a>
                    </ActiveLink>
                    <ActiveLink href="/services/cloud-and-devops">
                        <a>Cloud & Devops</a>
                    </ActiveLink>
                    <ActiveLink href="/services/cloud-operations">
                        <a>Cloud Operations</a>
                    </ActiveLink>
                </div>
            </button>
            <ActiveLink href="/resources">
                <a className={styles.navElement}>Resources</a>
            </ActiveLink>
            <ActiveLink href="/contact">
                <a className={styles.navElement}>Contact</a>
            </ActiveLink>
            <a className={`${styles.navElement} ${styles.joinUs}`} href="https://career.noria.no">
                Join us
            </a>
        </div>
    );
};

export default DesktopHeaderComponent;
