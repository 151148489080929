import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { FormatProps, StrapiImage } from '../RenderBlogPosts/RenderBlogPosts';
import styles from './media.module.scss';
export interface ImageProps {
    maxWidth?: number | string;
    maxHeight?: number | string;
    src: string;
    alt: string;
    formats?: Formats;
    minWidth?: number;
    isVideo?: boolean;
    loop?: boolean;
    extraClassNames?: string;
}

export interface StrapiImageComponentProps {
    maxWidth?: number;
    maxHeight?: number;
    strapiImage: StrapiImage;
}

interface Formats {
    large?: FormatProps;
    medium?: FormatProps;
    small?: FormatProps;
    thumbnail?: FormatProps;
}

export const MediaComponent = ({
    maxWidth,
    maxHeight,
    src,
    alt,
    minWidth = null,
    isVideo = false,
    loop = true,
    extraClassNames = '',
}: ImageProps) => {
    if (isVideo) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <video className={styles.videoContainer} autoPlay loop={loop} muted playsInline>
                    <source src={generateSrcUrl(src, false)} type="video/mp4" />
                </video>
            </div>
        );
    } else {
        return (
            <div className={`${extraClassNames}`} style={{ maxWidth, maxHeight, minWidth }}>
                <LazyLoadImage
                    src={generateSrcUrl(src, false)}
                    alt={alt}
                    style={{ maxWidth: '100%', maxHeight }}
                    width=""
                    effect="blur"
                    placeholderSrc={generateSrcUrl(src, false)}
                />
            </div>
        );
    }
};

export const StrapiImageComponent = ({
    maxWidth,
    maxHeight,
    strapiImage,
}: StrapiImageComponentProps) => {
    if (strapiImage) {
        if (strapiImage.formats) {
            return (
                <div style={{ maxWidth, maxHeight }}>
                    <LazyLoadImage
                        src={generateStrapiSrcUrl(strapiImage)}
                        alt={`Picture of ${strapiImage.alternativeText}`}
                        style={{ maxWidth: '100%', maxHeight }}
                        width=""
                        srcSet={generateSrcSet(strapiImage.formats)}
                        effect="blur"
                        placeholderSrc={generateStrapiSrcUrl(strapiImage)}
                    />
                </div>
            );
        } else {
            return (
                <div style={{ maxWidth, maxHeight }}>
                    <LazyLoadImage
                        src={generateSrcUrl(strapiImage.url, true)}
                        alt={`Picture of  ${strapiImage.alternativeText}`}
                        style={{ maxWidth: '100%', maxHeight }}
                        width=""
                        effect="blur"
                        placeholderSrc={generateSrcUrl(strapiImage.url, true)}
                    />
                </div>
            );
        }
    }

    return null;
};

const generateStrapiSrcUrl = (strapiImage: StrapiImage) => {
    if (strapiImage.formats?.medium) {
        return `${process.env.NEXT_PUBLIC_IMAGE_URL}${strapiImage.formats.medium.url}`;
    } else if (strapiImage.formats?.small) {
        return `${process.env.NEXT_PUBLIC_IMAGE_URL}${strapiImage.formats.small.url}`;
    } else {
        return `${process.env.NEXT_PUBLIC_IMAGE_URL}${strapiImage.url}`;
    }
};

const generateSrcUrl = (src: string, isStrapiMedia: boolean) => {
    if (src) {
        if (isStrapiMedia) {
            return `${process.env.NEXT_PUBLIC_IMAGE_URL}${src}`;
        } else {
            return `${process.env.NEXT_PUBLIC_WEBSITE_URL}${src}`;
        }
    } else {
        return null;
    }
};

const generateSrcSet = (formats: Formats) => {
    let finalSrcSet = '';
    if (formats.large?.url) {
        const large = generateSrcUrl(formats.large.url, true);
        finalSrcSet += `${large} 1500w, `;
    }
    if (formats.medium?.url) {
        const medium = generateSrcUrl(formats.medium.url, true);
        finalSrcSet += `${medium} 750w, `;
    }
    if (formats.small?.url) {
        const small = generateSrcUrl(formats.small.url, true);

        finalSrcSet += `${small} 500w, `;
    }
    return finalSrcSet;
};
