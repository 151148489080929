import React, { CSSProperties, ReactNode } from 'react';
import styles from '../layout.module.scss';

type LargeWidthContainer = {
    extraClassNames?: string;
    extraStyles?: CSSProperties;
    children: ReactNode;
    childrenClassNames?: string;
};

const LargeWidthContainer = ({
    extraClassNames = '',
    extraStyles,
    children,
    childrenClassNames,
}: LargeWidthContainer) => (
    <div className={`${styles.container1750} ${extraClassNames}`} style={extraStyles}>
        {childrenClassNames ? <div className={childrenClassNames}>{children}</div> : children}
    </div>
);

export default LargeWidthContainer;
