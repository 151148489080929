import Link from 'next/link';
import React from 'react';
import { useCookies } from 'react-cookie';
import FullWidthContainer from '../../Layout/Containers/FullWidthContainer';
import LargeWidthContainer from '../../Layout/Containers/LargeWidthContainer';
import styles from '../privacy.module.scss';

export const acceptPolicyCookie = 'accept-policy';

const CookieBannerComponent = () => {
    const [cookies, setCookie, removeCookie] = useCookies([acceptPolicyCookie]);

    const onClick = (acceptAll: boolean) => {
        if (acceptAll) {
            setCookie(acceptPolicyCookie, 'all', {
                path: '/',
            });
        } else {
            setCookie(acceptPolicyCookie, 'required', {
                path: '/',
            });
        }
    };

    return (
        <FullWidthContainer extraClassNames={styles.blackContainer}>
            <LargeWidthContainer extraStyles={{ height: '100%' }}>
                <div className={styles.cookieBannerContainer}>
                    <div>
                        This website uses cookies for an improved experience and analytics. Read our{' '}
                        <Link href="/privacy">
                            <a>privacy policy here.</a>
                        </Link>
                    </div>
                    <div className={styles.buttonGroup}>
                        <button
                            className="button-primary-inverted button-large"
                            onClick={() => onClick(true)}
                        >
                            Allow all cookies
                        </button>
                        <button
                            className="button-primary-inverted button-large"
                            style={{ width: '305px' }}
                            onClick={() => onClick(false)}
                        >
                            Use necessary cookies only
                        </button>
                    </div>
                </div>
            </LargeWidthContainer>
        </FullWidthContainer>
    );
};

export default CookieBannerComponent;
