import React, { CSSProperties, ReactNode } from 'react';
import styles from '../layout.module.scss';

type FullWidthContainerProps = {
    children: ReactNode;
    extraClassNames?: string;
    extraStyles?: CSSProperties;
};

const FullWidthContainer = ({
    children,
    extraClassNames = '',
    extraStyles,
}: FullWidthContainerProps) => (
    <div className={`${styles.containerFullwidth} ${extraClassNames}`} style={extraStyles}>
        {children}
    </div>
);

export default FullWidthContainer;
