import { useRouter } from 'next/router';

const activeDropdown = () => {
    const { asPath } = useRouter();
    if (aboutUs.includes(asPath)) {
        return 'about-us';
    } else if (asPath.indexOf('solutions') !== -1) {
        return 'solutions';
    } else if (asPath.indexOf('services') !== -1) {
        return 'services';
    } else {
        return '';
    }
};

const aboutUs = ['/about-us', '/about-us/management-team'];

export default activeDropdown;
