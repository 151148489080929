//@ts-nocheck -- Ignore type errors for now to make tests run

import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Children } from 'react';

// https://github.com/vercel/next.js/blob/canary/examples/active-class-name/components/ActiveLink.js

const ActiveLink = ({ children, ...props }) => {
    const { asPath } = useRouter();
    const child = Children.only(children);
    const childClassName = child.props.className || '';

    const urlIsActive = asPath === props.href || asPath === props.as;
    const className = urlIsActive ? `${childClassName} active` : childClassName;

    return (
        <Link {...props}>
            {React.cloneElement(child, {
                className: className || null,
            })}
        </Link>
    );
};

export default ActiveLink;
