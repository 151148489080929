import React from 'react';
import styles from '../header.module.scss';

const HeaderArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8">
        <path d="M.707.707l5.068 5.068L10.843.707" className={styles.headerArrow} />
    </svg>
);

export default HeaderArrow;
