import React, { useEffect, useState } from 'react';
import activeDropdown from './ActiveLink/activeDropdown';
import ActiveLink from './ActiveLink/ActiveLink';
import styles from './header.module.scss';
import HeaderArrow from './icons/HeaderArrow';

type HeaderProps = {
    setBackground: Function;
};

const MobileHeaderComponent = ({ setBackground: setBackgroundColor }: HeaderProps) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [solutionsOpen, setSolutionsOpen] = useState(false);
    const [servicesOpen, setServicesOpen] = useState(false);
    const [aboutUsOpen, setAboutUsOpen] = useState(false);

    useEffect(() => {
        document.body.style.overflowY = menuOpen ? 'hidden' : 'scroll';
        setBackgroundColor(!menuOpen ? styles.noBgNoFade : styles.bgNoFade);
    }, [menuOpen]);

    const onClick = () => setMenuOpen(!menuOpen);
    const onSolutionsClick = () => setSolutionsOpen(!solutionsOpen);
    const onServicesClick = () => setServicesOpen(!servicesOpen);
    const onAboutUsClick = () => setAboutUsOpen(!aboutUsOpen);

    return (
        <>
            <button
                onClick={onClick}
                aria-label="Open the navigation menu"
                className={`${styles.hamburgerButton} ${
                    menuOpen ? styles.hamburgerButtonOpen : ''
                }`}
            >
                <span></span>
                <span></span>
            </button>
            <div
                className={`${styles.hamburgerMenu} ${menuOpen ? styles.hamburgerMenuOpen : ''}`}
                aria-label="Navigation menu"
            >
                <button className={styles.navElement} onClick={onAboutUsClick}>
                    <span
                        className={`${styles.navElementDropdown} ${
                            aboutUsOpen ? styles.navElementDropdownOpen : ''
                        } ${activeDropdown() === 'about-us' ? 'active' : ''}`}
                    >
                        Company
                        <HeaderArrow />
                    </span>
                </button>
                <div
                    className={`${styles.navElementSubmenu} ${
                        aboutUsOpen ? styles.navElementSubmenuOpen : ''
                    }`}
                >
                    <ActiveLink href="/about-us">
                        <a className={styles.navElement}>About Noria</a>
                    </ActiveLink>
                    <ActiveLink href="/about-us/management-team">
                        <a className={styles.navElement}>Management team</a>
                    </ActiveLink>
                </div>

                <button className={styles.navElement} onClick={onSolutionsClick}>
                    <span
                        className={`${styles.navElementDropdown} ${
                            solutionsOpen ? styles.navElementDropdownOpen : ''
                        } ${activeDropdown() === 'solutions' ? 'active' : ''}`}
                    >
                        Solutions
                        <HeaderArrow />
                    </span>
                </button>
                <div
                    className={`${styles.navElementSubmenu} ${
                        solutionsOpen ? styles.navElementSubmenuOpen : ''
                    }`}
                >
                    <ActiveLink href="/solutions">
                        <a>Overview</a>
                    </ActiveLink>
                    <ActiveLink href="/solutions/insurance">
                        <a>Insurance Software</a>
                    </ActiveLink>
                    <ActiveLink href="/solutions/customer-centric-platform">
                        <a>Customer Centric Platform</a>
                    </ActiveLink>
                    <ActiveLink href="/solutions/insight">
                        <a>Insight</a>
                    </ActiveLink>
                </div>
                <button className={styles.navElement} onClick={onServicesClick}>
                    <span
                        className={`${styles.navElementDropdown} ${
                            servicesOpen ? styles.navElementDropdownOpen : ''
                        } ${activeDropdown() === 'services' ? 'active' : ''}`}
                    >
                        Services
                        <HeaderArrow />
                    </span>
                </button>
                <div
                    className={`${styles.navElementSubmenu} ${
                        servicesOpen ? styles.navElementSubmenuOpen : ''
                    }`}
                >
                    <ActiveLink href="/services">
                        <a>Overview</a>
                    </ActiveLink>
                    <ActiveLink href="/services/digital-strategy">
                        <a>Digital Strategy</a>
                    </ActiveLink>
                    <ActiveLink href="/services/transformation-delivery">
                        <a>Transformation Delivery</a>
                    </ActiveLink>
                    <ActiveLink href="/services/software-engineering-and-solution-architecture">
                        <a>Software Engineering & Solution Architecture</a>
                    </ActiveLink>
                    <ActiveLink href="/services/insights-and-analytics">
                        <a>Insights & Analytics</a>
                    </ActiveLink>
                    <ActiveLink href="/services/quality-assurance-and-test-engineering">
                        <a>Quality Assurance & Test Engineering</a>
                    </ActiveLink>
                    <ActiveLink href="/services/cloud-and-devops">
                        <a>Cloud & Devops</a>
                    </ActiveLink>
                    <ActiveLink href="/services/cloud-operations">
                        <a>Cloud Operations</a>
                    </ActiveLink>
                </div>
                <ActiveLink href="/resources">
                    <a className={styles.navElement}>Resources</a>
                </ActiveLink>
                <ActiveLink href="/contact">
                    <a className={styles.navElement}>Contact</a>
                </ActiveLink>

                <a
                    className={`${styles.navElement} ${styles.joinUs}`}
                    href="https://career.noria.no"
                >
                    Join us
                </a>
            </div>
        </>
    );
};

export default MobileHeaderComponent;
